.navigation-item-selected {
    background: rgba(var(--ion-color-primary-rgb), 0.14);
}

.navigation-icon {
    font-size: 20px;
}

.navigation-profile-link {
    display: flex;
    align-items: center;
}

.navigation-avatar {
    height: 28px;
    width: 28px;
}

.navigation-user {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.widget-wrapper {
    position: relative;
    width: 100%;
}

.widget-spinner {
    position: absolute;
    top: 0;
    right: 0;
}

.searchbar-wrapper {
    position: relative;
    border: 1px solid #EDF2F6;
    border-radius: var(--border-radius);
    margin: 3px 7px;
    padding: 4px;
    padding-left: 44px;
}

.searchbar-icon-search {
    position: absolute;
    left: 16px;
    top: 12px;
    width: 22px;
    height: 22px;
}

.profile-completion-photo {
    margin: auto;
    width: 200px;
    height: 200px;
    background-image: url('../../avatar.svg');
    background-size: 200px 200px;
    background-position: center;
}

.profile-completion-photo-img {
    opacity: 0;
}

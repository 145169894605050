.page-full-height {
    height: 100%;
}

.layout-full-height {
    height: calc(100% - 56px);  /* page height - toolbar height */
}

/* Fix to hide unnecessary vertical scrollbar on ion-select popover */
.popover-content.sc-ion-popover-md {
    overflow: hidden;
}

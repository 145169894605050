.form-footer {
    padding-top: 40px;
}

.form-footer__buttons-left {
    float: left;
}

.form-footer__buttons-right {
    float: right;
}

.form-footer__clear-both {
    clear: both;
}

.button-upload {
    margin: auto;
}
